module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Saratoga Springs Doodles","short_name":"SSD","start_url":"/","background_color":"#008080","theme_color":"#008080","display":"minimal-ui","icon":"src/images/saratogaspringsdoodles.webp","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f575180408fc85a42e2abdc8265779c7"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/opt/build/repo/src/cms/cms.js","htmlTitle":"Admin: Saratoga Springs Doodles","htmlFavicon":"/opt/build/repo/src/images/saratogaspringsdoodles.webp"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
